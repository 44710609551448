<script setup>
// @TODO Clean up error page:
// https://nuxt.com/docs/getting-started/error-handling#error-page

import { SplashScreen } from "@capacitor/splash-screen";
import { computedAsync } from "@vueuse/core";

const error = useError();

const { assistantId, ASSISTANT_ID_PARAM } = useGlobalConfig();

const goHome = () => {
  document.location.href = "/?" + ASSISTANT_ID_PARAM + "=" + assistantId.value;
};

const openAppStore = async () => {
  await AppUpdate.openAppStore();
};

const currentAppVersion = computedAsync(async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (Capacitor.getPlatform() === "android") {
    return result.currentVersionCode;
  } else {
    return result.currentVersionName;
  }
});

const availableAppVersion = computedAsync(async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  if (Capacitor.getPlatform() === "android") {
    return result.availableVersionCode;
  } else {
    return result.availableVersionName;
  }
});

const showUpdateButton = computed(
  () => currentAppVersion.value !== availableAppVersion.value
);

// Force hide splash screen when error
SplashScreen.hide();
</script>

<template>
  <div class="error">
    <div class="content">
      <template v-if="error.statusCode === 404">
        <h1>404</h1>
        <h2>Niet gevonden</h2>
        <p>Sorry, deze pagina lijkt niet (meer) te bestaan niet.</p>
        <p class="message">"{{ error.message }}"</p>
        <p v-if="!error.fatal">
          <button class="button" @click="goHome">Home</button>
        </p>
      </template>
      <template v-else-if="error.statusCode === 426">
        <h2>
          Update beschikbaar
          <template v-if="currentAppVersion && availableAppVersion">
            ({{ currentAppVersion }} > {{ availableAppVersion }})
          </template>
        </h2>
        <p>
          Om deze app te blijven gebruiken is het belangrijk om de app te
          updaten naar de laatste versie.
        </p>
        <p>
          <button v-if="showUpdateButton" class="button" @click="openAppStore">
            Update
          </button>
        </p>
      </template>
      <template v-else>
        <h1>{{ error.statusCode }}</h1>
        <h2>Foutje...</h2>
        <p>Sorry, er is iets mis te zijn gegaan:</p>
        <p class="message">"{{ error.message }}"</p>
        <p v-if="!error.fatal">
          <button class="button" @click="goHome">Home</button>
        </p>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.error {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100dvh;
  gap: 1rem;
  background-color: var(--main-body-background-color);
  color: var(--main-body-text-color);
  text-align: center;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  h1 {
    font-size: 5rem;
    margin: 0;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .message {
    font-style: italic;
  }

  .button {
    border: none;
    border-radius: 10px;
    background-color: #ff8cdf;
    color: #000000;
    font-weight: bold;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px 20px;

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }
}

// .error {
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   height: 100dvh;
//   gap: 1rem;
//   text-align: center;
//   overflow: auto;
//   -webkit-overflow-scrolling: touch;
// }

// .content {
//   display: flex;
//   flex-grow: 1;
//   flex-direction: column;
//   justify-content: center;
//   padding: 20px;
// }

// p {
//   margin-bottom: 20px;
// }
</style>
