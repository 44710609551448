import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { useGlobalConfig } from "@/composables/useGlobalConfig";
import { useFirebase } from "@/composables/useFirebase";

export default defineNuxtPlugin(() => {
  console.info("Initializing Firebase analytics");

  const { firebase, appId } = useRuntimeConfig().public;

  if (!firebase.apiKey) {
    console.warn("Firebase analytics disabled, missing API key");
    return;
  }

  const app = initializeApp(firebase);

  const { analytics } = useFirebase();

  analytics.value = getAnalytics(app);

  const { assistantId, settingsId } = useGlobalConfig();

  setUserProperties(analytics.value, {
    app_id: appId,
    assistant_id: assistantId.value,
    settings_id: settingsId.value,
  });
});
