import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  console.info("Initializing Sentry error logging");

  const { sentry } = useRuntimeConfig().public;

  if (!sentry.dsn) {
    console.warn("Sentry error logging disabled, missing DSN");
    return;
  }

  Sentry.init(
    {
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      enableNative: false, // https://github.com/getsentry/sentry-capacitor/issues/847

      integrations: [
        // Registers and configures the Tracing integration, which automatically instruments your
        // application to monitor its performance, including custom Angular routing instrumentation.
        // Sentry.browserTracingIntegration(),
        // Registers the Replay integration, which automatically captures Session Replays.
        // Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for tracing. We recommend
      // adjusting this value in production. Learn more at
      // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/api\.wijsneus\.app\/api/,
      ],

      // Set replaysSessionSampleRate to 0.1 to capture Capture Replay for 10% of all sessions,
      // plus set replaysOnErrorSampleRate to 1.0 for 100% of sessions with an error. Learn more at
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    },

    // Forward the init method from @sentry/vue
    SentryVue.init
  );
});
