import { useAssistantSettingsStore } from "@/stores/assistantSettingsStore";
import { snakeToKebab } from "@/utils";
import * as Sentry from "@sentry/vue";

interface Theme {
  [name: string]: string;
}

export default defineNuxtPlugin(async () => {
  const sanctumFetch = useSanctumClient();

  const fetchAssistantSettingsData = async () => {
    const assistantSettingsStore = useAssistantSettingsStore();

    let response;

    try {
      response = await sanctumFetch("/assistant-settings");
    } catch (e: unknown) {
      const error = useApiError(e);
      // We don't need to send validation errors to sentry
      if (!error.isValidationError) {
        Sentry.captureException(e);
      }
      throw e;
    }

    assistantSettingsStore.assistantSettings = response.data;

    applyAssistantTheme(response.data.theme);
  };

  const applyAssistantTheme = (theme: Theme) => {
    if (theme) {
      Object.keys(theme).forEach((key) => {
        const cssVariable = `--${snakeToKebab(key)}`;
        document.documentElement.style.setProperty(
          cssVariable,
          theme![key] ?? null
        );
      });
    }
  };

  await fetchAssistantSettingsData();
});
