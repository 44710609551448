import type { FetchContext } from "ofetch";
import type { ConsolaInstance } from "consola";
import { App } from "@capacitor/app";
import type { NuxtApp } from "#app";
import { Capacitor } from "@capacitor/core";
import { useGlobalConfig } from "@/composables/useGlobalConfig";
import { Keys, usePreferences } from "@/composables/usePreferences";

const { assistantId, settingsId, storeAssistantId, storeSettingsId } =
  useGlobalConfig();
const { getPreferences, setPreferences, removePreferences } = usePreferences();

export default defineAppConfig({
  sanctum: {
    tokenStorage: {
      get: async (app: NuxtApp) => {
        if (import.meta.server) return undefined;
        // @TODO Figure out how we can make sure to get the assistant ID before anything else. For
        // now it seems it's this place, but it seems random.
        storeAssistantId(app);
        storeSettingsId(app);
        // Get token from local storage
        const token = await getPreferences({
          key: Keys.token,
        });
        return token?.value ?? undefined;
      },

      set: async (app: NuxtApp, token?: string) => {
        if (import.meta.server) return;
        if (!token) {
          // Clear token from local storage
          await removePreferences({
            key: Keys.token,
          });
          return;
        }
        // Save token to local storage
        await setPreferences({
          key: Keys.token,
          value: token,
        });
      },
    },
    interceptors: {
      onRequest: async (
        _app: NuxtApp,
        ctx: FetchContext,
        logger: ConsolaInstance
      ) => {
        addAssistantIdHeader(ctx, logger);
        await addAppVersionHeader(ctx, logger);
        addSettingsIdHeader(ctx, logger);
      },
      onResponse: async (_app: NuxtApp, ctx: FetchContext) => {
        // Force error pages for specific status codes
        // console.log(ctx.response?.status);
        switch (ctx.response?.status) {
          case 426:
            // @TODO We need to set a timeout or else useScrollOnChatUpdate hides the error screen
            window.setTimeout(() => {
              showError({
                statusCode: 426,
                statusMessage: "Update required",
              });
            }, 100);
            break;
          default:
            break;
        }
      },
    },
  },
});

const addAssistantIdHeader = (ctx: FetchContext, logger: ConsolaInstance) => {
  // Add the asssistant ID to the request headers
  if (assistantId.value) {
    ctx.options.headers.set("X-Assistant-Id", assistantId.value);
    logger.debug(`Added header X-Assistant-Id: (${assistantId.value})`);
  } else {
    throw createError({
      statusCode: 404,
      message: "Missing Assistant ID",
      fatal: true,
    });
  }
};

const addAppVersionHeader = async (
  ctx: FetchContext,
  logger: ConsolaInstance
) => {
  // Add the app version to the request headers if the platform is native.
  if (Capacitor.isNativePlatform()) {
    const { version } = await App.getInfo();
    ctx.options.headers.set("X-App-Version", version);
    logger.debug(`Added header X-App-Version: (${version})`);
  }
};

const addSettingsIdHeader = (ctx: FetchContext, logger: ConsolaInstance) => {
  // Add settings ID parameter to header if found. This is used to load draft settings.
  if (settingsId.value) {
    ctx.options.headers.set("X-Settings-Id", settingsId.value);
    logger.debug(`Added header X-Settings-Id: (${settingsId.value})`);
  }
};
