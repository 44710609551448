import { ref } from "vue";
import type { NuxtApp } from "#app";

const ASSISTANT_ID_PARAM = "aid";
const SETTINGS_ID_PARAM = "sid";

const assistantId = ref();
const settingsId = ref();

export const useGlobalConfig = () => {
  const storeAssistantId = (app: NuxtApp) => {
    // Get the assistant id from the public config or the query parameter
    assistantId.value =
      app.$config.public.assistantId || useRoute().query[ASSISTANT_ID_PARAM];
  };

  const storeSettingsId = (app: NuxtApp) => {
    // Get the settings id from the public config or the query parameter
    settingsId.value =
      app.$config.public.settingsId || useRoute().query[SETTINGS_ID_PARAM];
  };

  return {
    ASSISTANT_ID_PARAM,
    SETTINGS_ID_PARAM,
    assistantId,
    settingsId,
    storeAssistantId,
    storeSettingsId,
  };
};
