import {
  Preferences,
  type GetOptions,
  type GetResult,
  type RemoveOptions,
  type SetOptions,
} from "@capacitor/preferences";
import { useGlobalConfig } from "@/composables/useGlobalConfig";

export enum Keys {
  assistantId = "assistantId",
  chatId = "chatId",
  chatHistory = "chatHistory",
  token = "token",
  userId = "userId",
  welcomeCompleted = "welcomeCompleted",
}

export const usePreferences = () => {
  const getPreferences = (options: GetOptions): Promise<GetResult> => {
    return Preferences.get({
      ...options,
      key: getPreferencesKey(options.key),
    });
  };

  const setPreferences = (options: SetOptions): Promise<void> => {
    return Preferences.set({
      ...options,
      key: getPreferencesKey(options.key),
    });
  };

  const removePreferences = (options: RemoveOptions): Promise<void> => {
    return Preferences.remove({
      ...options,
      key: getPreferencesKey(options.key),
    });
  };

  const clearPreferences = async (): Promise<void> => {
    const keys = Object.keys(Keys);

    for (const key of keys) {
      await removePreferences({ key });
    }
  };

  const getPreferencesKey = (key: string): string => {
    const { assistantId } = useGlobalConfig();
    return assistantId.value + "." + key;
  };

  return {
    getPreferences,
    setPreferences,
    removePreferences,
    clearPreferences,
  };
};
