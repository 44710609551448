import {
  setUserProperties as firebaseSetUserProperties,
  logEvent as firebaseLogEvent,
  type AnalyticsCallOptions,
  type CustomParams,
} from "firebase/analytics";

const analytics = ref();

export const useFirebase = () => {
  // Set user property function
  const setUserProperties = (
    properties: CustomParams,
    options?: AnalyticsCallOptions
  ): void => {
    try {
      if (analytics.value) {
        firebaseSetUserProperties(analytics.value, properties, options);
      }
    } catch (error) {
      console.error("Error setting user property: ", error);
    }
  };

  // Log event function
  const logEvent = (
    eventName: string,
    eventParams?: { [key: string]: unknown },
    options?: AnalyticsCallOptions
  ): void => {
    try {
      if (analytics.value) {
        firebaseLogEvent(analytics.value, eventName, eventParams, options);
      }
    } catch (error) {
      console.error("Error logging event: ", error);
    }
  };

  return {
    analytics,
    setUserProperties,
    logEvent,
  };
};
